/* generated by pull.js */
const manifest = {
  "editorOnly": true,
  "noTranslations": true,
  "name": "Editor sound effects",
  "description": "Plays sound effects when you connect or delete blocks.",
  "credits": [
    {
      "name": "GarboMuffin"
    }
  ],
  "userscripts": [
    {
      "url": "userscript.js"
    }
  ],
  "tags": [],
  "enabledByDefault": false
};
export default manifest;
