/* generated by pull.js */
const manifest = {
  "editorOnly": true,
  "noTranslations": true,
  "name": "Non-draggable sprites in editor",
  "description": "Removes the ability to drag sprites around on the stage in the editor, except those explicitly set as draggable.",
  "info": [
    {
      "type": "notice",
      "text": "Hold Shift while dragging a sprite to move it normally, even if it's not set as draggable.",
      "id": "shift"
    }
  ],
  "credits": [
    {
      "name": "Chrome_Cat",
      "link": "https://scratch.mit.edu/users/Chrome_Cat"
    },
    {
      "name": "GarboMuffin"
    }
  ],
  "userscripts": [
    {
      "url": "userscript.js"
    }
  ],
  "tags": [],
  "enabledByDefault": false,
  "dynamicDisable": true
};
export default manifest;
