/* generated by pull.js */
const manifest = {
  "noTranslations": true,
  "name": "Display stage on left side",
  "description": "Moves the stage to the left side of the editor.",
  "credits": [
    {
      "name": "NitroCipher/ZenithRogue"
    }
  ],
  "userscripts": [
    {
      "url": "fix-share-the-love.js"
    }
  ],
  "dynamicDisable": true,
  "userstyles": [
    {
      "url": "stageleft.css"
    }
  ],
  "tags": [
    "theme"
  ],
  "enabledByDefault": false
};
export default manifest;
