/* generated by pull.js */
const manifest = {
  "editorOnly": true,
  "name": "Block count",
  "description": "Shows the total number of blocks in a project in the editor menu bar. Previously part of \"sprite and script count\".",
  "tags": [],
  "credits": [
    {
      "name": "TheColaber",
      "link": "https://scratch.mit.edu/users/TheColaber"
    }
  ],
  "userscripts": [
    {
      "url": "blockcount.js"
    }
  ]
};
export default manifest;
