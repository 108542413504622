/* generated by pull.js */
const manifest = {
  "noTranslations": true,
  "name": "Muted project player mode",
  "description": "Ctrl+Click the green flag to mute/unmute the project.",
  "info": [
    {
      "type": "notice",
      "text": "On macOS, use the Cmd key instead of the Ctrl key.",
      "id": "macOS"
    }
  ],
  "credits": [
    {
      "name": "TheColaber",
      "link": "https://scratch.mit.edu/users/TheColaber"
    }
  ],
  "userscripts": [
    {
      "url": "userscript.js"
    }
  ],
  "enabledByDefault": true,
  "tags": [
    "recommended"
  ]
};
export default manifest;
