/* generated by pull.js */
const manifest = {
  "editorOnly": true,
  "name": "Save blocks as image",
  "description": "Right click the code area to export blocks as SVG/PNG images.",
  "tags": [],
  "credits": [
    {
      "name": "summerscar"
    }
  ],
  "userscripts": [
    {
      "url": "userscript.js"
    }
  ],
  "enabledByDefault": false
};
export default manifest;
